<template>
  <div
    class="bg-white z-[62] flex flex-col px-3 pb-4 min-[424px]:min-w-[360px] w-full"
  >
    <div v-if="closable" class="flex items-center justify-end -mr-3">
      <AppButton
        class="icon-button variant-invisible text-neutral h-[59px] w-[59px]"
        :icon="mdiClose"
        :aria-label="$t('common.closePopup')"
        @click="onClickCloseButton"
      />
    </div>
    <div
      class="w-full max-w-[390px] mx-auto font-bold sm:text-lg w-full text-center"
    >
      {{
        (forgotPassword ? $t('auth.forgotPassword') : title) || $t('auth.title')
      }}
    </div>
    <Transition
      enter-active-class="transition duration-[0.31] ease-in-out"
      enter-from-class="opacity-100"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-[0.31] ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="!forgotPassword && !hideRegister"
        class="w-full max-w-[390px] mx-auto border-b border-line-light mb-2"
      >
        <Tabs
          v-model:selected="selectedTab"
          :tabs="[
            { label: $t('auth.signIn') },
            { label: $t('auth.signUp') }
            // { label: $t('auth.forgotPassword') }
          ]"
          class="-mb-[0.75px] w-full text-sm md:text-base"
        />
      </div>
    </Transition>
    <Transition
      mode="out-in"
      enter-active-class="transition duration-[0.31] ease-in-out"
      enter-from-class="opacity-100"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-[0.31] ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <form
        v-if="forgotPassword"
        class="w-full max-w-[390px] mx-auto flex flex-col gap-0.5 pt-4"
        @submit.prevent="submitResetPassword"
      >
        <div class="text-center my-1 mx-auto max-w-[270px]">
          {{ $t('auth.forgotPasswordHelp') }}
        </div>
        <div>
          <label for="email">{{ $t('auth.email') }}</label>
          <AppInput
            id="email"
            v-model="email"
            class="w-full variant-outline-lg"
            :class="{
              'input-error': resetPasswordError
            }"
            :placeholder="$t('auth.emailPlaceholder')"
            autocomplete="email"
            :rules="[
              v => !!v || $t('auth.emailRequired'),
              () => emailIsValid || $t('auth.emailInvalid')
            ]"
          />
        </div>
        <div
          v-if="resetPasswordError"
          class="px-1 text-danger max-w-[270px] -mb-3"
        >
          {{ resetPasswordError }}
        </div>
        <AppButton
          class="w-full h-[42px] px-2 rounded mt-5"
          :disabled="!emailIsValid"
          type="submit"
        >
          {{ $t('auth.forgotPasswordSubmit') }}
        </AppButton>
        <div class="text-center my-1 [text-wrap:balance] mx-auto">
          {{ $t('auth.knowPasswordQuestion') }}
          <button
            type="button"
            class="text-blue font-bold py-2"
            @click="
              forgotPassword = false;
              selectedTab = 0;
            "
          >
            {{ $t('auth.signIn') }}
          </button>
        </div>
      </form>
      <div
        v-else
        class="w-full max-w-[390px] mx-auto flex-1 overflow-y-auto px-1"
      >
        <form
          v-if="selectedTab === 0 || hideRegister"
          class="flex flex-col gap-0.5"
          @submit.prevent="signIn"
        >
          <div>
            <label for="email">{{ $t('auth.email') }}</label>
            <AppInput
              id="email"
              v-model="email"
              class="w-full variant-outline-lg"
              :class="{
                'input-error': signInError
              }"
              :placeholder="$t('auth.emailPlaceholder')"
              autocomplete="email"
              :rules="[
                v => !!v || $t('auth.emailRequired'),
                () => emailIsValid || $t('auth.emailInvalid')
              ]"
            />
          </div>
          <div>
            <label for="password">{{ $t('auth.password') }}</label>
            <AppInput
              id="password"
              v-model="password"
              class="w-full variant-outline-lg"
              :class="{
                'input-error': signInError
              }"
              :placeholder="$t('auth.passwordPlaceholder')"
              type="password"
              autocomplete="current-password"
              :rules="[
                v => !!v || $t('auth.passwordRequired'),
                () => passwordIsValid || $t('auth.passwordInvalid')
              ]"
            />
          </div>
          <div v-if="signInError" class="px-1 text-danger max-w-[270px] -mb-3">
            {{ signInError }}
          </div>
          <div
            v-if="forgotPasswordSuccess"
            class="text-blue-dark max-w-[270px]"
          >
            {{ $t('auth.forgotPasswordSuccess') }}
          </div>
          <AppButton
            class="w-full h-[42px] px-2 rounded mt-5"
            :disabled="!emailIsValid || !passwordIsValid"
            type="submit"
          >
            {{ $t('auth.submitSignIn') }}
          </AppButton>
          <button
            type="button"
            class="text-blue font-bold py-2"
            @click="forgotPassword = true"
          >
            {{ $t('auth.forgotPasswordQuestion') }}
          </button>
        </form>
        <form
          v-else-if="selectedTab === 1 && !hideRegister"
          class="flex flex-col gap-0.5"
          @submit.prevent="createAccount"
        >
          <div>
            <label for="email">{{ $t('auth.emailNewAccount') }}</label>
            <AppInput
              id="email"
              v-model="email"
              class="w-full variant-outline-lg"
              :class="{
                'input-error': signUpError
              }"
              :placeholder="$t('auth.emailPlaceholder')"
              autocomplete="email"
              :rules="[
                v => !!v || $t('auth.emailRequired'),
                () => emailIsValid || $t('auth.emailInvalid')
              ]"
            />
          </div>
          <div>
            <label for="password">{{ $t('auth.passwordNewAccount') }}</label>
            <AppInput
              id="password"
              v-model="newPassword"
              class="w-full variant-outline-lg"
              :class="{
                'input-error': signUpError
              }"
              :placeholder="$t('auth.createPasswordPlaceholder')"
              type="password"
              autocomplete="new-password"
              :rules="[
                v => !!v || $t('auth.passwordRequired'),
                () => newPasswordIsValid || $t('auth.passwordInvalid')
              ]"
            />
          </div>
          <div v-if="signUpError" class="px-1 text-danger max-w-[270px] -mb-3">
            {{ signUpError }}
          </div>
          <ul class="text-xs md:text-sm text-neutral-light pt-2 px-2 -mb-3">
            <li>{{ $t('auth.charactersAmountHint') }}</li>
            <!-- <li>{{ $t('auth.characterMixHint') }}</li> -->
            <!-- <li>{{ $t('auth.specialCharacterHint') }}</li> -->
          </ul>
          <AppButton
            class="w-full h-[42px] px-2 rounded mt-5"
            :disabled="!emailIsValid || !newPasswordIsValid"
            type="submit"
          >
            {{ $t('auth.submitSignUp') }}
          </AppButton>
          <div
            class="text-xs text-center mt-1 mb-2 [text-wrap:balance] mx-auto max-w-[270px]"
          >
            {{ $t('auth.signInDisclaimer') }}
          </div>
        </form>
        <div class="flex flex-col gap-0.5">
          <div class="w-full h-px bg-line-light"></div>
          <div class="text-center my-1 [text-wrap:balance] mx-auto">
            {{ $t('auth.orConnectWith') }}
          </div>
          <AppButton
            class="variant-generic [--button-color:#DB4437] text-white h-[42px] rounded left-button px-3 mb-2"
            @click="() => signInSocial('google')"
          >
            <Icon :path="mdiGoogle" />
            <span>Google</span>
          </AppButton>
          <AppButton
            class="variant-generic [--button-color:#006AFF] text-white h-[42px] rounded left-button px-3"
            @click="() => signInSocial('facebook')"
          >
            <Icon :path="mdiFacebook" />
            <span>Facebook</span>
          </AppButton>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { mdiFacebook, mdiGoogle, mdiLinkedin } from '@mdi/js';
import type { AuthErrorPayload } from '@nhost/nhost-js';
import { useApolloClient } from '@vue/apollo-composable';

import { xSocial } from '@/utils/icons';

const AppInput = defineAsyncComponent(
  () => import('@/async-components/App/Input.vue')
);
const Tabs = defineAsyncComponent(
  () => import('@/async-components/Tabs/Tabs.vue')
);

const nhost = useNhost();

const { user } = useAuth();

const injectedUser = inject('user', null) as Ref<User | null | false>;

declare global {
  interface Window {
    userId: null | string;
    userEmail: null | string;
    apolloClient: ApolloClient | null;
  }
}

watch(
  user,
  () => {
    if (injectedUser) {
      injectedUser.value = user.value;
    }
    if (typeof window !== 'undefined') {
      window.userId = user.value?.id || null;
      window.userEmail = user.value?.email || null;
    }
  },
  {
    immediate: true
  }
);

const { t } = useI18n();

const { addToast } = useToasts();

const { triggerEvent } = useHubspot();

const { email, emailIsValid, resetPassword, resetPasswordError } =
  usePasswordResetRequest(false);

defineProps({
  closable: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  hideRegister: {
    type: Boolean,
    default: false
  }
});

type Emits = {
  (e: 'close'): void;
};

const emit = defineEmits<Emits>();

const selectedTab = ref(0);

const forgotPassword = ref(false);

const forgotPasswordSuccess = ref(false);

const password = ref('');
const newPassword = ref('');

async function submitResetPassword() {
  const success = await resetPassword();
  if (success) {
    forgotPassword.value = false;
    selectedTab.value = 0;
    forgotPasswordSuccess.value = true;
    setTimeout(() => {
      forgotPasswordSuccess.value = false;
    }, 5000);
  }
}

const passwordIsValid = computed(() => {
  return password.value.length > 7;
});

const newPasswordIsValid = computed(() => {
  return newPassword.value.length > 7;
});

function isAuthError(e: unknown): e is AuthErrorPayload {
  return typeof e === 'object' && e !== null && 'message' in e && 'status' in e;
}

const signInError = ref('');
const signUpError = ref('');

async function signIn() {
  try {
    signInError.value = '';
    const result = await nhost.auth.signIn({
      email: email.value,
      password: password.value
    });
    if (result.error) {
      throw result.error;
    }
    addToast({
      title: t('common.signInSuccess'),
      type: 'success'
    });
    console.log('Sign in result', result);
    email.value = '';
    password.value = '';
  } catch (e) {
    console.log('Sign in error', e);
    if (isAuthError(e)) {
      signInError.value = t(
        `auth.signInErrors.${e.status}`,
        null,
        t('auth.signInErrors.unknown')
      );
    } else {
      signInError.value = t('auth.signInErrors.unknown');
    }
  }
}

async function signInSocial(
  provider: 'google' | 'facebook' | 'linkedin' | 'twitter'
) {
  try {
    signInError.value = '';
    const result = await nhost.auth.signIn({
      provider,
      options: { redirectTo: window.location.href }
    });
    if (result.error) {
      throw result.error;
    }
    console.log('Sign in result', result);
  } catch (e) {
    console.log('Sign in error', e);
    if (isAuthError(e)) {
      signInError.value = t(
        `auth.signInErrors.${e.status}`,
        null,
        t('auth.signInErrors.unknown')
      );
    } else {
      signInError.value = t('auth.signInErrors.unknown');
    }
  }
}

async function createAccount() {
  try {
    signUpError.value = '';
    const result = await nhost.auth.signUp({
      email: email.value,
      password: newPassword.value,
      options: {
        locale: 'es'
      }
    });
    if (result.error) {
      throw result.error;
    }
    triggerEvent('pe23281212_sign_up', {
      saved: email.value
    });
    addToast({
      title: t('common.signUpSuccess'),
      type: 'success'
    });
    console.log('Sign up result', result);
    email.value = '';
    newPassword.value = '';
  } catch (e) {
    console.log('Sign up error', e);
    if (isAuthError(e)) {
      signUpError.value = t(
        `auth.signUpErrors.${e.status}`,
        null,
        t('auth.signUpErrors.unknown')
      );
    } else {
      signUpError.value = t('auth.signUpErrors.unknown');
    }
  }
}

async function signOut() {
  await nhost.auth.signOut();
}

function onClickCloseButton() {
  forgotPassword.value = false;
  selectedTab.value = 0;
  emit('close');
}

onMounted(() => {
  // inject apolloClient to window object

  if (typeof window !== 'undefined') {
    window.apolloClient = useApolloClient();
  }
});

defineExpose({
  signOut
});
</script>
